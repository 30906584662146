import * as React from "react"
import { graphql } from "gatsby"

import Layout from "./layout2022"
import Seo from "../../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const JournalIndex = ({ data, location }) => {
  return (
    <Layout location={location}>
      <Seo title="220816 - Summer Night" />
      <h3 className="font-title-sub text-center">Summer Night</h3>
      <div className="text-center">
        <StaticImage
          formats={["auto", "webp", "avif"]}
          src="../../../images/journal/2022/sketch_220816.jpg"
          width={600}
          quality={95}
        />
      </div>
      <p>After visited the shrine in the morning, I spent this afternoon watching movies. Then my friend texted me to go to see fireworks. It seemed to start raining outside though, so we decided to go to eat Yakiniku instead.</p>
      <p>Every time I got on the train, I saw the restaurant from train windows, and we finally visited. The cozy atmosphere and dishes were affordable and good.</p>
      <p>We left the restaurant, and got ice creams, spaklers, and ramune sodas at the supermarket (I often went to this supermarket when was in high school). After back my home, we enjoyed small fireworks drinking ramune. It was very summer night.</p>
    </Layout>
  )
}

export default JournalIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
